import styled from "styled-components";
import { theme } from "themeConfig";

export const StyledGsap = styled.div`
  .section {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100%;
    min-height: 100vh;

    z-index: -1;
  }

  .section01 {
    height: fit-content;
    min-height: unset;
  }

  #sections {
  }

  #images {
    position: fixed;
    width: 100%;
    right: 0;
    height: 100vh;
  }
  #images > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: visible;
  }

  .section00 {
    display: flex;
    flex-direction: column;
    height: 100vh;
    min-height: 600px;
  }

  @media screen and (min-width: ${theme.breakpoints.small}) {
  }
`;

interface IBackgrounds {
  background: string;
}

export const StyledBrandInfo = styled.div<IBackgrounds>`
  background-color: white;
  background-image: url("${(props) => props.background}");
  background-size: cover;
  background-repeat: no-repeat;

  width: 100vw;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left !important;

  h2 {
    text-transform: uppercase;
    color: ${theme.colors.newBlue};
    font-family: ${theme.fonts.secondary};
    text-align: left;
    font-size: 46px;
    font-weight: 700;
  }

  .text {
    font-family: ${theme.fonts.primary};
    font-size: 22px;

    img {
      width: 1em;
      height: auto;
    }
  }

  .imageCont {
    display: flex;
    justify-content: center;
  }
  padding: 22px;

  @media screen and (max-width: ${theme.breakpoints.small}) {
    padding: 22px;

    .text {
      white-space: revert;
    }
  }
`;
