import React, { useEffect } from "react";
import { StyledSlideTwo } from "./Slide2.styled";
import Link from "next/link";
import { WPImage } from "@/components/WPImage/WPImage";

// GSAP
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import ParticleImage from "@/components/ParticleImage/ParticleImage";
import Button from "@/components/Button/Button";
if (typeof window !== "undefined") {
  gsap.registerPlugin(ScrollTrigger);
}

type Props = {
  pageProps: {
    button_link: string;
    button_text: string;
    button_text_color: string;
    button_background: string;
    particle_image: {
      alt: string;
      url: string;
      width: string;
      height: string;
    };
    image: {
      alt: string;
      url: string;
      width: string;
      height: string;
    };
    title: string;
    slogan_text: string;
    color: string;
    text_color: string;
    button_icon_left: string;
    button_icon_right: string;
  };
};

const Slide2 = ({ pageProps }: Props) => {
  const {
    button_link,
    button_text,
    button_text_color,
    button_background,
    particle_image,
    image,
    title,
    slogan_text,
    color,
    text_color,
    button_icon_left,
    button_icon_right,
  } = pageProps;

  return (
    <StyledSlideTwo textColor={text_color} productColor={color}>
      <div className="main-inner">
        <div className="S2__container">
          {slogan_text && <p className="S2__slogan h4-caps">{slogan_text}</p>}
          <h2 className="S2__title h1-caps">{title && title}</h2>

          <Link href={button_link}>
            <Button
              icon_left={button_icon_left}
              icon_right={button_icon_right}
              color={button_text_color}
              background={button_background}
              link={button_link}
              title={button_text}
            />
          </Link>
        </div>
      </div>
    </StyledSlideTwo>
  );
};

export default Slide2;
